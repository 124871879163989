import { useNavigate } from 'react-router-dom';
import '../src/App.css';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import 'react-simple-keyboard/build/css/index.css';

import {
  selectFirebaseStatusField,
  selectISFirebaseSocketOn,
  selectPrefix,
  selectUserCode,
  selectUserType,
  setHeight,
  setWidth,
} from './utility/Redux/profile';

import geniusLogo from './utility/json/Genius-logo-v1.json';

import AlertModalTwoButton from './utility/component/modals/alertWithTwoButton';
import AlertModal from './utility/component/modals/alert';

import SpinnerLoading from './utility/component/modals/spinnerLoading';
import database from './routeBilling/issueStock/firebase';
import { child, get, ref, set } from 'firebase/database';
import { setBillSattalmentModalStatus } from './utility/Redux/modal';
import AlertModal2 from './utility/component/modals/alertWithLottie';
import AppRoutes from './routes';
import Lottie from 'lottie-react';
import LicenseExpiredModal from './utility/component/licenseExpiredModal';
import moment from 'moment';

function App() {
  const tkn = localStorage.getItem('tkn');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [screenMode, setScreenMode] = useState(window.screen.orientation.type);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const dispatch = useDispatch();

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  const prefix = useSelector(selectPrefix);

  const handleResize = () => {
    dispatch(setWidth(window.innerWidth));
    dispatch(setHeight(window.innerHeight));
    setScreenMode(window.screen.orientation.type);
  };

  const userType = useSelector(selectUserType);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, 3000); // Adjust the time as needed
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (window.innerWidth >= 784) {
      if (userAgent.match(/android|webos|ipad|tablet|kindle|playbook|silk/i)) {
        if (screenMode.includes('portrait')) {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'FullScreen',
            msg: 'To use this app with best experience allow to full screen',
            button1: 'ok',

            button1Click: () => {
              const element = document.documentElement;
              if (element.requestFullscreen) {
                element.requestFullscreen();

                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode

                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });

                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();

                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode
                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();

                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                if (window.screen.orientation?.lock) {
                  try {
                    // Lock the screen orientation to portrait mode
                    window.screen.orientation
                      .lock('landscape')

                      .catch((err) => {
                        console.error('Error locking screen orientation:', err);
                      });
                    setScreenMode('landscape-primary');
                  } catch (error) {
                    alert(error);
                  }
                }
              }
            },
          });
        }
      }
    }
  }, [screenMode]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const userCode = useSelector(selectUserCode);
  const statusField = useSelector(selectFirebaseStatusField);

  const navigate = useNavigate();
  const connected = ref(database, `${prefix}/users/${userCode}/`);
  const connectedR = ref(
    database,
    `${prefix}/users/${userCode}/${statusField}`
  );

  const isFieebaseSocketOn = useSelector(selectISFirebaseSocketOn);

  useEffect(() => {
    if (
      prefix &&
      userCode &&
      localStorage.getItem('expiresAt') &&
      isFieebaseSocketOn &&
      window.location.href.includes('auth')
    ) {
      get(child(connected, `/${statusField}`)).then((sn) => {
        if (sn.exists()) {
          const data = sn.val();
          if (data.includes(localStorage.getItem('expiresAt'))) {
            set(connectedR, `d-${localStorage.getItem('expiresAt')}`);
            localStorage.removeItem('userType');
            dispatch(setBillSattalmentModalStatus(false));
            localStorage.removeItem('stationName');
            localStorage.removeItem('stationId');
            localStorage.removeItem('billDetails');
            localStorage.removeItem('userName');
            localStorage.removeItem('count');
            localStorage.removeItem('tkn');
            localStorage.removeItem('company');
            localStorage.removeItem('histryId');
            localStorage.removeItem('prefix');
            localStorage.removeItem('expiresAt');
            localStorage.removeItem('orderdItemList');
            localStorage.removeItem('companyDetails');
            localStorage.removeItem('email');
            localStorage.removeItem('dev');
            localStorage.removeItem('devloper');
            localStorage.removeItem('userDefaultSetting');
            localStorage.removeItem('statusField');
            navigate('/auth/login');
            window.location.reload();
          } else {
            localStorage.removeItem('userType');
            dispatch(setBillSattalmentModalStatus(false));
            localStorage.removeItem('stationName');
            localStorage.removeItem('stationId');
            localStorage.removeItem('billDetails');
            localStorage.removeItem('userName');
            localStorage.removeItem('count');
            localStorage.removeItem('tkn');
            localStorage.removeItem('company');
            localStorage.removeItem('histryId');
            localStorage.removeItem('prefix');
            localStorage.removeItem('expiresAt');
            localStorage.removeItem('orderdItemList');
            localStorage.removeItem('companyDetails');
            localStorage.removeItem('email');
            localStorage.removeItem('dev');
            localStorage.removeItem('devloper');
            localStorage.removeItem('userDefaultSetting');
            localStorage.removeItem('statusField');
            navigate('/auth/login');
            window.location.reload();
          }
        }
      });
    }
  }, [navigate]);

  return (
    <>
      {showModal && !tkn ? (
        <div
          style={{
            height: window.innerHeight,
          }}
          className=" flex items-center justify-center "
        >
          <Lottie
            animationData={geniusLogo}
            loop={true}
            style={{
              fontSize: 15,
              height:
                window.innerWidth <= 1280
                  ? window.innerHeight * 0.1 * 6
                  : window.innerHeight * 0.1 * 3.5,
            }}
            color="red"
          />
        </div>
      ) : (
        <AppRoutes userType={userType} />
      )}

      <AlertModalTwoButton details={alertTwoButtonModal} />
      <SpinnerLoading />
      <AlertModal2 />
      <AlertModal />
      {/* <LicenseExpiredModal
        isOpen={true}
        expirationDate={moment().format('YYYY-MM-DD')}
      /> */}
    </>
  );
}

export default App;
