import { useDispatch, useSelector } from 'react-redux';

import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import { useEffect } from 'react';
import { selectWidth } from '../utility/Redux/profile';

import MasterHelp from '../utility/component/modals/masterHelp';
import CallAPI from '../utility/functions/getData';

const PromoMessagehelp = ({ status, handleClose, onRowClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const [fullList, setFullList] = useState([]);
  const token = useSelector(selectToken);
  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      field: 'NAME',
      renderHeader: (params) => <div>Name</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'COMPANY_NAME',
      renderHeader: (params) => <div>Company</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'EMAIL',
      renderHeader: (params) => <div>Email</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MSG_MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'PRODUCT_NAME',
      renderHeader: (params) => <div>Product Name</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  const getData = () => {
    CallAPI(
      '/api/PromoMessageMaster/listPromoMsgMaster',
      {
        CODE: '',
      },
      token,
      (res) => {
        if (!res.data.error) {
          setRows(res.data.response);
          setFullList(res.data.response);
        }
      },
      dispatch
    );
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  return (
    <>
      <MasterHelp
        status={status}
        style={style}
        onClose={() => {
          handleClose();
          setRows([]);
        }}
        title={'Promo Messenger Master | Help'}
        columns={columns}
        list={rows}
        rowId={(r) => r.CODE}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          setSearchQuery('');
          setRows([]);
          onRowClick(p);
        }}
      />
    </>
  );
};

export default PromoMessagehelp;
