import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDefultProfileSetting,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../Redux/profile';
import { parseISO } from 'date-fns';

import DatePicker from 'react-datepicker';

import {
  selectCollectionAccountDetails,
  setCollectionAccountDetails,
  setItemMasterHelpStatus,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
} from '../Redux/modal';

const width = window.innerWidth / 100;

export const FromToDate = (props) => {
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const dispatch = useDispatch();
  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState('');

  const userType = useSelector(selectUserType);
  const accDetails = useSelector(selectCollectionAccountDetails);
  const accountDetails = useSelector(selectCollectionAccountDetails);
  const ReduxStation_id = useSelector(selectStationId);
  const ReduxStationName = useSelector(selectStationName);

  const { accNumber } = accountDetails;
  const windowWidth = useSelector(selectWidth);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  return (
    <div className="w-full">
      <div
        style={{ borderRadius: '7px', overflow: 'hidden' }}
        className=" flex w-full "
      >
        <div className="flex border  border-[#b5b3b3] rounded-l-sm   border-r-0">
          {props?.date?.status === false ? null : (
            <div className=" flex items-center  border-[#a6a2a2] border-r p-1  px-4 ">
              <div
                style={{ fontSize: 13 }}
                className=" whitespace-nowrap text-[#4B4545]"
              >
                {props?.customerFromDateText
                  ? props.customerFromDateText
                  : 'From'}
              </div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderRadius: '7px',
                }}
                className="flex border  ml-2 text-[#4B4545] overflow-hidden  "
              >
                <div
                  style={{
                    padding: '2px',
                    borderRight: '1px solid #a6a2a2 ',
                  }}
                  className="bg-[#f1efeb] overflow-hidden "
                >
                  <DatePicker
                    onFocus={() => setfirstDateFocus(true)}
                    onClickOutside={() => setfirstDateFocus(false)}
                    onSelect={() => setfirstDateFocus(false)}
                    open={
                      userType === 'USER'
                        ? defaultProfile?.enable_user_dashboard_date === 'YES'
                          ? firstdateFocus
                          : false
                        : firstdateFocus
                    }
                    readOnly={true}
                    className=" bg-transparent text-sm w-36 outline-0  "
                    dateFormat=" d MMMM , yyyy "
                    selected={parseISO(firstDate)}
                    onChange={(date) =>
                      dispatch(
                        setreportsFromDate(moment(date).format('YYYY-MM-DD'))
                      )
                    }
                  />
                </div>

                <div
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
                  }}
                  className=" items-center flex justify-start px-2  bg-[#f7dda4] rounded-r-sm"
                >
                  <i
                    onClick={() => {
                      if (
                        userType === 'USER'
                          ? defaultProfile?.enable_user_dashboard_date === 'YES'
                          : true
                      ) {
                        setfirstDateFocus(true);
                      }
                    }}
                    className="bi bi-calendar2-minus"
                  ></i>
                </div>
              </div>

              <div
                style={{ fontSize: 13 }}
                className="  text-[#4B4545] px-4 p-3"
              >
                To
              </div>
              <div
                style={{ borderRadius: '7px', border: '1px solid #b5b3b3' }}
                className="flex  overflow-hidden "
              >
                <div
                  style={{
                    padding: '2px',
                    borderRight: '1px solid #a6a2a2',
                  }}
                  className=" text-[#4B4545] bg-[#f1efeb]   "
                >
                  <DatePicker
                    onFocus={() => SetSecondDateFocus(true)}
                    onClickOutside={() => SetSecondDateFocus(false)}
                    onSelect={() => SetSecondDateFocus(false)}
                    open={
                      userType === 'USER'
                        ? defaultProfile?.enable_user_dashboard_date === 'YES'
                          ? secondDateFocus
                          : false
                        : secondDateFocus
                    }
                    readOnly={true}
                    style={{ fontSize: 13 }}
                    className=" bg-transparent w-36 text-sm outline-0   "
                    dateFormat=" d MMMM , yyyy "
                    selected={parseISO(SecondDate)}
                    onChange={(date) =>
                      dispatch(
                        setreportsTodate(moment(date).format('YYYY-MM-DD'))
                      )
                    }
                  />
                </div>

                <div
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
                  }}
                  className=" items-center text-[#4B4545]  flex justify-start px-2 bg-[#f7dda4] rounded-r-sm"
                >
                  <i
                    onClick={() => {
                      if (
                        userType === 'USER'
                          ? defaultProfile?.enable_user_dashboard_date === 'YES'
                          : true
                      ) {
                        SetSecondDateFocus(true);
                      }
                    }}
                    className="bi bi-calendar2-minus"
                  ></i>
                </div>
              </div>
            </div>
          )}

          {/* item code */}

          {props?.itemCode?.status === true ? (
            <div
              // onClick={() => dispatch(setcollVoucherAccModStatus(true))}
              style={{ borderRight: '1px solid #a6a2a2' }}
              className="px-4 flex justify-center items-center print:hidden "
            >
              <div
                className="flex items-center"
                onClick={() => dispatch(setItemMasterHelpStatus(true))}
              >
                <i
                  class="bi bi-search"
                  style={{ color: '#f44336', fontSize: 15 }}
                ></i>
                <span
                  style={
                    window.innerWidth <= 425
                      ? { fontSize: 13, width: width * 20 }
                      : { fontSize: 13, width: width * 7 }
                  }
                  className="font-semibold text-[#4B4545] w-24 whitespace-nowrap  mr-1 pl-2 flex"
                >
                  {' '}
                  Item Code{' '}
                </span>
              </div>

              <div
                style={{
                  border: '1px dotted #a6a2a2 ',
                  borderRadius: '3px',
                }}
                className="flex text-[#a6a2a2]  px-2  "
              >
                <input
                  size={'small'}
                  placeholder="All"
                  value={
                    props?.itemCode?.itemCode
                      ? props?.itemCode?.itemCode
                      : 'All'
                  }
                  style={{
                    fontSize: 12,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    backgroundColor: 'transparent',
                  }}
                  readOnly
                  className=" outline-0 w-6 "
                />
                {props?.itemCode?.itemCode && (
                  <button
                    onClick={() => {
                      props?.itemCode?.setItemCode('');
                    }}
                  >
                    <i className="bi text-sm  text-red-400 bi-backspace-fill font-semibold"></i>
                  </button>
                )}
              </div>
            </div>
          ) : null}

          {/* station id */}

          <div className="flex px-4  text-[#4B4545] p-3  ">
            {userType === 'USER' ? null : !props?.hideStation ? (
              <div
                style={{ fontSize: 13 }}
                className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
              >
                <div className="flex items-center ">
                  <button
                    onClick={() => {
                      if (userType === 'MANAGER') {
                        dispatch(setListStationMasterModalStatus(true));
                      }
                    }}
                  >
                    <i
                      className="bi bi-search"
                      style={{ color: '#f44336', fontSize: 15 }}
                    ></i>
                  </button>

                  <span
                    style={{ fontSize: 12, fontWeight: 600 }}
                    className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                  >
                    {' '}
                    Station ID{' '}
                  </span>
                </div>

                <div
                  style={{
                    border: '1px dotted #a6a2a2 ',
                    borderRadius: '3px',
                  }}
                  className="flex text-[#a6a2a2]  pl-2 ml-2  "
                >
                  <input
                    size={'small'}
                    placeholder="All"
                    value={ReduxStation_id ? ReduxStation_id : 'All'}
                    style={{
                      fontSize: 12,
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      backgroundColor: 'transparent',
                    }}
                    readOnly
                    className=" outline-0 w-6 "
                  />

                  <div
                    style={{
                      borderLeft: '1px dotted #a6a2a2 ',
                    }}
                    className="px-2 p-1 flex whitespace-nowrap print:hidden items-center"
                  >
                    {ReduxStationName ? `${ReduxStationName}` : null}
                    {ReduxStationName && (
                      <button
                        onClick={() => {
                          dispatch(setStationID(''));
                          dispatch(setStationName(''));
                        }}
                      >
                        <i className="bi ml-2 text-red-500 bi-backspace-fill font-semibold"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="flex">
              <button
                onClick={props.submit}
                className="flex items-center rounded-sm ml-2"
                style={{
                  backgroundColor: '#c1c1c1',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: 'black',
                }}
              >
                <div className="flex items-center text-sm">Ok</div>
              </button>
              {props.otherButton && props.otherButton}
            </div>
          </div>
        </div>

        {/* other */}

        <div
          style={{
            border: '1px dotted #a6a2a2 ',
          }}
          className="flex grow rounded-r-sm overflow-hidden "
        >
          {/* Party details 2nd part*/}
          {props?.filter ? (
            <props.filter />
          ) : (
            !props.hideBorder && (
              <div
                style={{
                  borderRight: '1px dotted #a6a2a2',
                }}
                className="flex w-full h-full "
              >
                <div
                  style={{
                    display: windowWidth > 1280 ? 'flex' : 'none',
                  }}
                  className="flex grow h-full "
                >
                  <div className="w-full h-full">
                    <div
                      className=" h-1/2 print:h-full flex items-center"
                      style={{
                        fontSize: 12,
                        color: 'red',
                      }}
                    ></div>
                    <div
                      style={{
                        borderTop: '1px dotted #a6a2a2',
                        color: '#f2666f',
                        fontSize: 12,
                      }}
                      className="h-1/2 flex items-center print:hidden"
                    ></div>
                  </div>
                </div>
              </div>
            )
          )}

          {/* Party details 3rd part*/}
          {!props.hideBorder && (
            <div className="px-2 flex justify-center items-center print:hidden overflow-hidden">
              <i
                className="bi bi-backspace-fill"
                style={{ color: '#f2666f', fontSize: 18 }}
                onClick={props.reset}
              ></i>
            </div>
          )}
        </div>
      </div>
      {props?.partyName ? (
        <div
          style={{
            border: '1px solid #b5b3b3',
          }}
          className="w-full flex mt-1 rounded-sm "
        >
          <div
            style={
              userType === 'MANAGER'
                ? {
                    fontSize: 12,

                    color: '#a6a2a2',
                    // borderRight: '0.5px solid #4B4545',
                  }
                : {
                    fontSize: 12,
                    color: '#a6a2a2',
                  }
            }
            className=" flex whitespace-normal p-2    justify-start items-center  "
          >
            {/* party name 1st part */}
            <div className="flex  h-full items-center ">
              <div
                className="flex hover:cursor-pointer h-full items-center"
                onClick={
                  props.onPartySearchClick
                    ? props.onPartySearchClick
                    : () => dispatch(setModPartyMasterHelpStatus(true))
                }
              >
                <div>
                  <i
                    className="bi bi-search"
                    style={{ color: '#f44336', fontSize: 15 }}
                  ></i>
                </div>

                <span
                  style={{ fontSize: 12 }}
                  className=" text-[#5c5b5b]  pl-2 flex font-semibold items-center"
                >
                  {' '}
                  Party{' '}
                  <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                    {' '}
                    Name{' '}
                  </span>
                </span>
              </div>

              <div
                style={{
                  border: '1px dotted #a6a2a2 ',
                  borderRadius: '3px',
                }}
                className="flex  w-20  pl-2 ml-2 "
              >
                <input
                  size={'small'}
                  placeholder="All"
                  value={accNumber ? accNumber : 'All'}
                  style={{
                    fontSize: 12,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    backgroundColor: 'transparent',
                  }}
                  readOnly
                  className=" outline-0 w-full  "
                />
                {accNumber && (
                  <button
                    onClick={() => {
                      dispatch(
                        setCollectionAccountDetails({
                          accName: '',
                          accAddress: '',
                          accMobile: '',
                          accNumber: '',
                          stationId: '',
                          sName: '',
                        })
                      );
                    }}
                    className="pr-3"
                  >
                    <i className="bi text-red-500 bi-backspace-fill font-semibold"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
          {props?.partyName !== false && accDetails.accName ? (
            <div className="flex border-l grow  ">
              <div className="w-full h-full">
                <div
                  className=" h-1/2 print:h-full flex items-center"
                  style={{
                    fontSize: 12,
                    color: 'red',
                  }}
                >
                  <span className="pl-4 font-semibold ">
                    {accDetails.accName}
                  </span>
                </div>
                <div
                  style={{
                    borderTop: '1px dotted #a6a2a2',
                    color: '#f2666f',
                    fontSize: 12,
                  }}
                  className="h-1/2 flex items-center print:hidden"
                >
                  <span className="pl-4 font-semibold  ">
                    {accDetails.accAddress + ' '}
                    {accDetails.accMobile}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
