import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa'; // Alert icon
import AppIcon from '../images/GOROUND2.png'; // Replace with your app logo
import moment from 'moment';

const LicenseExpiredModal = ({ expirationDate, isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render if the modal is closed

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg relative animate-fadeIn">
        {/* App Icon & Header */}
        <div className="flex items-center space-x-3">
          <h3 className="text-xl font-semibold text-red-600 flex items-center">
            <FaExclamationTriangle className="mr-2" /> License will expire soon
          </h3>
        </div>

        {/* Message */}
        <p className="text-gray-700 mt-4">
          Your license will expire on <strong>{expirationDate}</strong>. You
          have{' '}
          <label className="text-red-500">
            <strong>{moment(expirationDate).diff(moment(), 'days')}</strong>{' '}
            days left.
          </label>
        </p>

        {/* Buttons */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-100"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default LicenseExpiredModal;
