import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';
import Store from '../../../../../utility/Redux/store';

const isLocalhost = window.location.hostname === 'localhost';
export const apiClient = axios.create({
  // baseURL: isLocalhost
  //   ? 'http://192.168.1.4:5000'
  //   : 'https://www.routebilling.online', // Replace with your API base URL
  baseURL: isLocalhost
    ? 'https://www.routebilling.online'
    : 'https://www.routebilling.online', // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Send cookies with requests if needed
});
export const CallAPIPromise = (path, data, token, dispatch) => {
  const state = Store.getState();
  const tokenLocal = state.security.token;
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${
    token ? token : tokenLocal
  }`;
  return new Promise((resolve, reject) => {
    try {
      apiClient
        .post(path, data)
        .then((t) => {
          resolve(t);
        })
        .catch((error) => {
          // dispatch(setSpinnerLoading(false));
          console.log(error);

          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          } else {
            if (error.response.data?.message) {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: error.response.data?.message,
                })
              );
              return;
            }
            reject(error);
          }
        });
    } catch {
      reject('Try Catch');
    }
  });
};
