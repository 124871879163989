import { useState } from 'react';

export default function SocialPost() {
  const [caption, setCaption] = useState('');
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [platforms, setPlatforms] = useState({
    facebook: false,
    instagram: false,
  });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handlePlatformChange = (e) => {
    setPlatforms({ ...platforms, [e.target.name]: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Posting:', { caption, file, platforms });
    // API integration will go here
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-bold mb-4">Create Social Media Post</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          className="w-full p-2 border rounded mb-3"
          placeholder="Write a caption..."
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
        ></textarea>
        <input
          type="file"
          className="w-full mb-3"
          accept="image/*"
          onChange={handleFileChange}
        />
        {preview && (
          <img
            src={preview}
            alt="Preview"
            className="w-full h-40 object-cover rounded mb-3"
          />
        )}
        <div className="flex items-center gap-4 mb-3">
          <label>
            <input
              type="checkbox"
              name="facebook"
              className="mr-2"
              checked={platforms.facebook}
              onChange={handlePlatformChange}
            />
            Facebook
          </label>
          <label>
            <input
              type="checkbox"
              name="instagram"
              className="mr-2"
              checked={platforms.instagram}
              onChange={handlePlatformChange}
            />
            Instagram
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Post
        </button>
      </form>
    </div>
  );
}
